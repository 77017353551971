// import { useContext } from 'react';
// import { useTranslation } from 'react-i18next';
// import {
//   ListSubheader,
//   alpha,
//   Box,
//   List,
//   styled,
//   Button,
//   ListItem
// } from '@mui/material';
// import { NavLink as RouterLink } from 'react-router-dom';
// import { SidebarContext } from 'src/contexts/SidebarContext';
// import HomeIcon from '@mui/icons-material/Home';
// import MessageIcon from '@mui/icons-material/Message';
// import FavoriteIcon from '@mui/icons-material/Favorite';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
// import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
// import WysiwygIcon from '@mui/icons-material/Wysiwyg';
// import { useTheme } from '@mui/material/styles';
// const MenuWrapper = styled(Box)(
//   ({ theme }) => `
//   .MuiList-root {
//     padding: ${theme.spacing(1)};

//     & > .MuiList-root {
//       padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
//     }
//   }

//   .MuiListSubheader-root {
//     text-transform: uppercase;
//     font-weight: bold;
//     font-size: ${theme.typography.pxToRem(12)};
//     color: ${theme.colors.alpha.trueWhite[50]};
//     padding: ${theme.spacing(0, 2.5)};
//     line-height: 1.4;
//   }
// `
// );

// const SubMenuWrapper = styled(Box)(
//   ({ theme }) => `
//     .MuiList-root {

//       .MuiListItem-root {
//         padding: 1px 0;

//         .MuiBadge-root {
//           position: absolute;
//           right: ${theme.spacing(3.2)};

//           .MuiBadge-standard {
//             background: ${theme.colors.primary.main};
//             font-size: ${theme.typography.pxToRem(10)};
//             font-weight: bold;
//             text-transform: uppercase;
//             color: ${theme.palette.primary.contrastText};
//           }
//         }

//         .MuiButton-root {
//           display: flex;
//           color: ${theme.colors.alpha.trueWhite[70]};
//           background-color: transparent;
//           width: 100%;
//           justify-content: flex-start;
//           padding: ${theme.spacing(1.2, 3)};

//           .MuiButton-startIcon,
//           .MuiButton-endIcon {
//             transition: ${theme.transitions.create(['color'])};

//             .MuiSvgIcon-root {
//               font-size: inherit;
//               transition: none;
//             }
//           }

//           .MuiButton-startIcon {
//             color: ${theme.colors.alpha.trueWhite[30]};
//             font-size: ${theme.typography.pxToRem(20)};
//             margin-right: ${theme.spacing(1)};
//           }

//           .MuiButton-endIcon {
//             color: ${theme.colors.alpha.trueWhite[50]};
//             margin-left: auto;
//             opacity: .8;
//             font-size: ${theme.typography.pxToRem(20)};
//           }

//           &.active,
//           &:hover {
//             background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
//             color: ${theme.colors.alpha.trueWhite[100]};

//             .MuiButton-startIcon,
//             .MuiButton-endIcon {
//               color: ${theme.colors.alpha.trueWhite[100]};
//             }
//           }
//         }

//         &.Mui-children {
//           flex-direction: column;

//           .MuiBadge-root {
//             position: absolute;
//             right: ${theme.spacing(7)};
//           }
//         }

//         .MuiCollapse-root {
//           width: 100%;

//           .MuiList-root {
//             padding: ${theme.spacing(1, 0)};
//           }

//           .MuiListItem-root {
//             padding: 1px 0;

//             .MuiButton-root {
//               padding: ${theme.spacing(0.8, 3)};

//               .MuiBadge-root {
//                 right: ${theme.spacing(3.2)};
//               }

//               &:before {
//                 content: ' ';
//                 background: ${theme.colors.alpha.trueWhite[100]};
//                 opacity: 0;
//                 transition: ${theme.transitions.create([
//                   'transform',
//                   'opacity'
//                 ])};
//                 width: 6px;
//                 height: 6px;
//                 transform: scale(0);
//                 transform-origin: center;
//                 border-radius: 20px;
//                 margin-right: ${theme.spacing(1.8)};
//               }

//               &.active,
//               &:hover {

//                 &:before {
//                   transform: scale(1);
//                   opacity: 1;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
// `
// );

// function SidebarMenu() {
//   const { closeSidebar } = useContext(SidebarContext);
//   const { t } = useTranslation();
//   const theme = useTheme();
//   const isRTL = theme.direction === 'rtl';
//   const iconStyle = {
//     color: '#18E68E',
//     marginRight: isRTL ? 0 : theme.spacing(1),
//     marginLeft: isRTL ? theme.spacing(1) : 0
//   };
//   return (
//     <>
//       <MenuWrapper>
//         <List component="div">
//           <SubMenuWrapper>
//             <List
//               component="div"
//               subheader={
//                 <ListSubheader component="div" disableSticky>
//                   {t('Dashboard')}
//                 </ListSubheader>
//               }
//             >
//               <ListItem component="div">
//                 <Button
//                   disableRipple
//                   component={RouterLink}
//                   onClick={closeSidebar}
//                   to="/dashboards/home"
//                   startIcon={<HomeIcon style={{ color: '#18E68E' }} />}
//                 >
//                   <div style={{ fontSize: '16px' }}>{t('Home')}</div>
//                 </Button>
//               </ListItem>
//               <ListItem component="div">
//                 <Button
//                   disableRipple
//                   component={RouterLink}
//                   onClick={closeSidebar}
//                   to="/dashboards/map"
//                   startIcon={<LocationOnIcon style={{ color: '#18E68E' }} />}
//                 >
//                   <div style={{ fontSize: '16px' }}>{t('Map')}</div>
//                 </Button>
//               </ListItem>
//             </List>
//           </SubMenuWrapper>
//         </List>
//         <List component="div">
//           <SubMenuWrapper>
//             <List
//               component="div"
//               subheader={
//                 <ListSubheader component="div" disableSticky>
//                   {t('Account')}
//                 </ListSubheader>
//               }
//             >
//               <ListItem component="div">
//                 <Button
//                   disableRipple
//                   component={RouterLink}
//                   onClick={closeSidebar}
//                   to="/profil"
//                   startIcon={
//                     <AccountCircleTwoToneIcon style={{ color: '#18E68E' }} />
//                   }
//                 >
//                   <div style={{ fontSize: '16px' }}>{t('Profile')}</div>
//                 </Button>
//               </ListItem>
//             </List>
//           </SubMenuWrapper>
//         </List>
//         <List component="div">
//           <SubMenuWrapper>
//             <List
//               component="div"
//               subheader={
//                 <ListSubheader component="div" disableSticky>
//                   {t('Activities')}
//                 </ListSubheader>
//               }
//             >
//               <ListItem component="div">
//                 <Button
//                   disableRipple
//                   component={RouterLink}
//                   onClick={closeSidebar}
//                   to="/dashboards/claims"
//                   startIcon={<WysiwygIcon style={{ color: '#18E68E' }} />}
//                 >
//                   <div style={{ fontSize: '16px' }}> {t('Claims')}</div>
//                 </Button>
//               </ListItem>

//               <ListItem component="div">
//                 <Button
//                   disableRipple
//                   component={RouterLink}
//                   onClick={closeSidebar}
//                   to="/dashboards/messenger"
//                   startIcon={<MessageIcon style={{ color: '#18E68E' }} />}
//                 >
//                   <div style={{ fontSize: '16px' }}>{t('Messages')}</div>
//                 </Button>
//               </ListItem>
//               <ListItem component="div">
//                 <Button
//                   disableRipple
//                   component={RouterLink}
//                   onClick={closeSidebar}
//                   to="/dashboards/favourites"
//                   startIcon={<FavoriteIcon style={{ color: '#18E68E' }} />}
//                 >
//                   <div style={{ fontSize: '16px' }}> {t('Favorites')}</div>
//                 </Button>
//               </ListItem>
//             </List>
//           </SubMenuWrapper>
//         </List>
//       </MenuWrapper>
//     </>
//   );
// }

// export default SidebarMenu;

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { useTheme } from '@mui/material/styles';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

  .MuiListSubheader-root {
    text-transform: uppercase;
    font-weight: bold;
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.colors.alpha.trueWhite[50]};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
    text-align: ${theme.direction === 'rtl' ? 'right' : 'left'};
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      direction: ${theme.direction};

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.direction === 'rtl' ? 'auto' : theme.spacing(3.2)};
          left: ${theme.direction === 'rtl' ? theme.spacing(3.2) : 'auto'};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};
          direction: ${theme.direction};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.direction === 'rtl' ? 0 : theme.spacing(1)};
            margin-left: ${theme.direction === 'rtl' ? theme.spacing(1) : 0};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: ${theme.direction === 'rtl' ? 0 : 'auto'};
            margin-right: ${theme.direction === 'rtl' ? 'auto' : 0};
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.direction === 'rtl' ? 'auto' : theme.spacing(7)};
            left: ${theme.direction === 'rtl' ? theme.spacing(7) : 'auto'};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${
                  theme.direction === 'rtl' ? 'auto' : theme.spacing(3.2)
                };
                left: ${
                  theme.direction === 'rtl' ? theme.spacing(3.2) : 'auto'
                };
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${
                  theme.direction === 'rtl' ? 0 : theme.spacing(1.8)
                };
                margin-left: ${
                  theme.direction === 'rtl' ? theme.spacing(1.8) : 0
                };
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';
  const iconStyle = {
    color: '#18E68E',
    marginRight: isRTL ? 0 : theme.spacing(1),
    marginLeft: isRTL ? theme.spacing(1) : 0
  };
  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {t('Dashboard')}
                </ListSubheader>
              }
            >
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/home"
                  startIcon={<HomeIcon style={iconStyle} />}
                >
                  <div style={{ fontSize: '16px' }}>{t('Home')}</div>
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/map"
                  startIcon={<LocationOnIcon style={iconStyle} />}
                >
                  <div style={{ fontSize: '16px' }}>{t('Map')}</div>
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List component="div">
          <SubMenuWrapper>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {t('Account')}
                </ListSubheader>
              }
            >
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/profil"
                  startIcon={<AccountCircleTwoToneIcon style={iconStyle} />}
                >
                  <div style={{ fontSize: '16px' }}>{t('Profile')}</div>
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List component="div">
          <SubMenuWrapper>
            <List
              component="div"
              subheader={
                <ListSubheader component="div" disableSticky>
                  {t('Activities')}
                </ListSubheader>
              }
            >
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/claims"
                  startIcon={<WysiwygIcon style={iconStyle} />}
                >
                  <div style={{ fontSize: '16px' }}> {t('Claims')}</div>
                </Button>
              </ListItem>

              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/messenger"
                  startIcon={<MessageIcon style={iconStyle} />}
                >
                  <div style={{ fontSize: '16px' }}>{t('Messages')}</div>
                </Button>
              </ListItem>
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/dashboards/favourites"
                  startIcon={<FavoriteIcon style={iconStyle} />}
                >
                  <div style={{ fontSize: '16px' }}> {t('Favorites')}</div>
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
