// import { useContext } from 'react';
// import Scrollbar from 'src/components/Scrollbar';
// import { SidebarContext } from 'src/contexts/SidebarContext';

// import {
//   Box,
//   Drawer,
//   alpha,
//   styled,
//   Divider,
//   useTheme,
//   lighten,
//   darken
// } from '@mui/material';

// import SidebarMenu from './SidebarMenu';
// import HeaderUserbox from '../Header/Userbox';

// const SidebarWrapper = styled(Box)(
//   ({ theme }) => `
//         width: ${theme.sidebar.width};
//         min-width: ${theme.sidebar.width};
//         color: ${theme.colors.alpha.trueWhite[70]};
//         position: relative;
//         z-index: 7;
//         height: 100%;
//         padding-bottom: 68px;

// `
// );

// function Sidebar() {
//   const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
//   const closeSidebar = () => toggleSidebar();
//   const theme = useTheme();

//   return (
//     <>
//       <SidebarWrapper
//         sx={{
//           display: {
//             xs: 'none',
//             lg: 'inline-block'
//           },
//           position: 'fixed',

//           left: theme.direction === 'ltr' ? 0 : 'auto',
//           right: theme.direction === 'rtl' ? 0 : 'auto',
//           top: 80,
//           background:
//             theme.palette.mode === 'dark'
//               ? alpha(lighten(theme.header.background, 0.1), 0.25)
//               : darken(theme.colors.alpha.black[100], 0.5),
//           boxShadow:
//             theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
//         }}
//       >
//         <Scrollbar>
//           <Divider
//             sx={{
//               mt: theme.spacing(3),
//               mx: theme.spacing(2),
//               background: theme.colors.alpha.trueWhite[10]
//             }}
//           />
//           <SidebarMenu />
//         </Scrollbar>
//         <Divider
//           sx={{
//             background: theme.colors.alpha.trueWhite[10]
//           }}
//         />
//         <Box
//           display="flex"
//           alignItems="center"
//           justifyContent={'center'}
//           className="p-2"
//         >
//           <HeaderUserbox />
//         </Box>
//       </SidebarWrapper>
//       <Drawer
//         sx={{
//           boxShadow: `${theme.sidebar.boxShadow}`
//         }}
//         anchor={theme.direction === 'rtl' ? 'left' : 'right'}
//         open={sidebarToggle}
//         onClose={closeSidebar}
//         variant="temporary"
//         elevation={9}
//       >
//         <SidebarWrapper
//           sx={{
//             background:
//               theme.palette.mode === 'dark'
//                 ? theme.colors.alpha.white[100]
//                 : darken(theme.colors.alpha.black[100], 0.5)
//           }}
//         >
//           <Scrollbar>
//             <Box mt={3} />
//             <Divider
//               sx={{
//                 mt: theme.spacing(3),
//                 mx: theme.spacing(2),
//                 background: theme.colors.alpha.trueWhite[10]
//               }}
//             />
//             <SidebarMenu />
//           </Scrollbar>
//         </SidebarWrapper>
//       </Drawer>
//     </>
//   );
// }

// export default Sidebar;

import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
  Box,
  Drawer,
  alpha,
  styled,
  Divider,
  useTheme,
  lighten,
  darken
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import HeaderUserbox from '../Header/Userbox';

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: isRTL ? 'auto' : 0,
          right: isRTL ? 0 : 'auto',
          top: 80,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.25)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent={'center'}
          className="p-2"
        >
          <HeaderUserbox />
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={isRTL ? 'left' : 'right'} // Ajuster l'ancre en fonction de la direction
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5)
          }}
        >
          <Scrollbar>
            <Box mt={3} />
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
