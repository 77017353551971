/* eslint-disable global-require */
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';
import api from 'src/redux/config';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, logout } from 'src/redux/authSlice';
import { useTranslation } from 'react-i18next';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: ${theme.direction === 'rtl' ? 'right' : 'left'};
        padding-${
          theme.direction === 'rtl' ? 'right' : 'left'
        }: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { t, i18n } = useTranslation(); 
  const [imageData, setImageData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authUser = useSelector((x) => x.client);
  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const user = {
    name:
      authUser?.profile?.firstname && authUser?.profile?.lastname
        ? `${authUser.profile.firstname} ${authUser.profile.lastname}`
        : authUser?.profile?.email || '',
    avatar: '/static/images/avatars/4.jpg',
    jobtitle: authUser?.profile?.userKind || ''
  };

  useEffect(() => {
    api
      .get(`/user/profile-picture`, {
        headers: {
          Authorization: authUser.accessToken
        },
        responseType: 'arraybuffer'
      })
      .then((response) => {
        const arrayBufferView = new Uint8Array(response.data);
        const blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        setImageData(imageUrl);
      })
      .catch((error) => {
        console.error('Error fetching profile picture:', error);
      });
  }, []);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        {imageData ? (
          <Avatar
            variant="rounded"
            alt={user.name}
            src={imageData}
            style={{ borderRadius: 100 }}
          />
        ) : (
          <Avatar
            variant="rounded"
            alt="f"
            src={require('../../../../assets/images/profile.png')}
          />
        )}
        <Hidden mdDown>
          <UserBoxText className={i18n.dir()}>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: i18n.dir() === 'rtl' ? 'left' : 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: i18n.dir() === 'rtl' ? 'left' : 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          {imageData ? (
            <Avatar variant="rounded" alt={user.name} src={imageData} />
          ) : (
            <Avatar
              variant="rounded"
              alt="f"
              src={require('../../../../assets/images/profile.png')}
            />
          )}
          <UserBoxText className={i18n.dir()}>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/profil" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('My Profile')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
