import { useContext, useEffect, useState } from 'react';
import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Logo from 'src/components/LogoSign';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';
import api from 'src/redux/config';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        left: 0;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            width: auto;
        }
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl'; // Vérifier si la direction est RTL
  const isLgUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [notifications, setNotifications] = useState([]);

  const fetchNotification = () => {
    Promise.all([
      api.get('/notification/all'),
      api.get(`/notification/${localStorage?.getItem('company')}`)
    ])
      .then(([allNotificationsRes, companyNotificationsRes]) => {
        const allNotificationsData = allNotificationsRes.data || [];
        const companyNotificationsData = companyNotificationsRes.data || [];
        const mergedNotifications = [
          ...allNotificationsData,
          ...companyNotificationsData
        ];
        setNotifications(mergedNotifications);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      {isLgUp && (
        <Box
          mx={11}
          sx={{
            width: 50,
            marginLeft: isRTL ? 0 : theme.spacing(11),
            marginRight: isRTL ? theme.spacing(11) : 0
          }}
        >
          <Logo />
        </Box>
      )}
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <HeaderMenu />
      </Stack>
      <Box display="flex" alignItems="center">
        <HeaderButtons
          notifications={notifications}
          fetchNotification={fetchNotification}
        />
        <HeaderUserbox />
        <Box
          component="span"
          sx={{
            marginLeft: isRTL ? 0 : theme.spacing(2),
            marginRight: isRTL ? theme.spacing(2) : 0,
            display: { lg: 'none', xs: 'inline-block' }
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
