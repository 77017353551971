import React, { useState } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {
  const curThemeName = localStorage.getItem('appTheme') || 'NebulaFighterTheme';
  const [themeName, _setThemeName] = useState(curThemeName);

  // Créer le thème avec la direction reçue en props
  const baseTheme = themeCreator(themeName);
  const theme = createTheme({
    ...baseTheme,
    direction: props.direction || 'ltr'
  });

  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <MuiThemeProvider theme={theme}>
          <div dir={theme.direction}>{props.children}</div>
        </MuiThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
