import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translations/en.json';
import frTranslation from './translations/fr.json';
import grTranslation from './translations/gr.json';
import espTranslation from './translations/esp.json';
import arTranslation from './translations/ar.json';
import portTranslation from './translations/port.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: { translation: enTranslation },
  fr: { translation: frTranslation },
  ar: { translation: arTranslation },
  de: { translation: grTranslation },
  port: { translation: portTranslation },
  esp: { translation: espTranslation }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || 'fr',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
